import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Loader from './shared/Loader';
import { AiOutlineEye, AiOutlineEyeInvisible, AiOutlineArrowLeft } from 'react-icons/ai';

const Form = ({ setAuthState }) => {
  const [mobile, setMobile] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [otp, setOtp] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [showNewPassword, setShowNewPassword] = useState(false); // State to toggle new password visibility
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false); // State to toggle confirm new password visibility

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (isForgotPassword) {
      if (!otpSent) {
        if (!mobile) {
          setError('Mobile number is required.');
          setLoading(false);
          return;
        }
      } else {
        if (!otp || !newPassword || !confirmNewPassword) {
          setError('All fields are required to reset the password.');
          setLoading(false);
          return;
        }
        if (newPassword !== confirmNewPassword) {
          setError('New password and confirmation do not match.');
          setLoading(false);
          return;
        }
      }
    } else {
      if (!mobile || !password) {
        setError('Mobile number and password are required.');
        setLoading(false);
        return;
      }
    }

    try {
      let url;
      let method;
      let body;

      if (isForgotPassword) {
        if (!otpSent) {
          url = 'https://backend.crumbell.in/api/v1/auth/forget-password';
          method = 'PATCH';
          body = { mobile };
        } else {
          url = 'https://backend.crumbell.in/api/v1/auth/reset-password';
          method = 'PATCH';
          body = { mobile, otp, newPassword, confirmNewPassword };
        }
      } else {
        url = 'https://backend.crumbell.in/api/v1/auth/login';
        method = 'POST';
        body = { mobile, password };
      }

      const response = await fetch(url, {
        method,
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      });

      const contentType = response.headers.get('content-type');
      if (contentType && contentType.includes('application/json')) {
        const result = await response.json();
        if (response.ok) {
          if (!isForgotPassword) {
            localStorage.setItem("token", result.token);
            localStorage.setItem("tokenExpiration", result.expiration);
            localStorage.setItem("UserId", result.user.id);
            setAuthState('authenticated');
            navigate('/');
          } else {
            if (!otpSent) {
              setOtpSent(true);
              setError('');
              alert('Otp send successfully');
            } else {
              setError('');
              navigate('/login');
              alert('Password updated successfully');
              setIsForgotPassword(false);
            }
          }
        } else {
          setError(result.message);
        }
      } else {
        const text = await response.text();
        setError('An unexpected error occurred.');
      }
    } catch (error) {
      navigate('/login');
      alert('Password updated successfully');
      setError('');
      setIsForgotPassword(false)
    } finally {
      setLoading(false);
    }
  };

  const handleBack = () => {
    if (otpSent) {
      setOtpSent(false);
    } else if (isForgotPassword) {
      setIsForgotPassword(false);
    }
    setError('');
  };

  return (
    <div className='flex items-center justify-center min-h-screen bg-gray-100'>
      <div className='w-11/12 max-w-[700px] px-10 py-20 rounded-3xl bg-white border-2 border-gray-100'>
        {loading && <Loader />}
        <div className='flex items-center justify-between mb-8'>
          {(isForgotPassword || otpSent) && (
            <button
              type="button"
              onClick={handleBack}
              className='text-violet-500 font-medium flex items-center'
            >
              <AiOutlineArrowLeft size={24} className='mr-2' /> Back
            </button>
          )}
          <h1 className='text-5xl font-semibold'>{isForgotPassword ? 'Forgot Password' : 'Welcome Back'}</h1>
        </div>
        <p className='font-medium text-lg text-gray-500 mt-4'>
          {isForgotPassword ? (otpSent ? 'Enter OTP and set a new password.' : 'Enter your mobile number to receive an OTP.') : 'Welcome back! Please enter your details.'}
        </p>
        <form onSubmit={handleSubmit} className='mt-8'>
          {!isForgotPassword && (
            <>
              <div className='flex flex-col'>
                <label className='text-lg font-medium'>Mobile</label>
                <input
                  className='w-full border-2 border-gray-100 rounded-xl p-4 mt-1 bg-transparent'
                  placeholder="Enter your Mobile No."
                  value={mobile}
                  onChange={(e) => setMobile(e.target.value)}
                />
              </div>
              <div className='flex flex-col mt-4 relative'>
                <label className='text-lg font-medium'>Password</label>
                <div className='relative'>
                  <input
                    className='w-full border-2 border-gray-100 rounded-xl p-4 mt-1 bg-transparent pr-12'
                    placeholder="Enter your password"
                    type={showPassword ? 'text' : 'password'}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <button
                    type="button"
                    onClick={() => setShowPassword(!showPassword)}
                    className='absolute inset-y-0 right-4 flex items-center'
                    aria-label={showPassword ? 'Hide password' : 'Show password'}
                  >
                    {showPassword ? <AiOutlineEyeInvisible size={24} /> : <AiOutlineEye size={24} />}
                  </button>
                </div>
              </div>
              <div className='mt-8 flex justify-between items-center'>
                <div>
                  <input type="checkbox" id='remember' />
                  <label className='ml-2 font-medium text-base' htmlFor="remember">Remember for 30 days</label>
                </div>
                <button
                  type="button"
                  onClick={() => setIsForgotPassword(true)}
                  className='font-medium text-base text-violet-500'
                >
                  Forgot password
                </button>
              </div>
            </>
          )}

          {isForgotPassword && !otpSent && (
            <>
              <div className='flex flex-col'>
                <label className='text-lg font-medium'>Mobile</label>
                <input
                  className='w-full border-2 border-gray-100 rounded-xl p-4 mt-1 bg-transparent'
                  placeholder="Enter your Mobile No."
                  value={mobile}
                  onChange={(e) => setMobile(e.target.value)}
                />
              </div>
            </>
          )}

          {isForgotPassword && otpSent && (
            <>
              <div className='flex flex-col'>
                <label className='text-lg font-medium'>Enter OTP</label>
                <input
                  className='w-full border-2 border-gray-100 rounded-xl p-4 mt-1 bg-transparent'
                  placeholder="Enter the OTP sent to your mobile"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                />
              </div>
              <div className='flex flex-col mt-4 relative'>
                <label className='text-lg font-medium'>New Password</label>
                <div className='relative'>
                  <input
                    className='w-full border-2 border-gray-100 rounded-xl p-4 mt-1 bg-transparent pr-12'
                    placeholder="Enter your new password"
                    type={showNewPassword ? 'text' : 'password'}
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                  />
                  <button
                    type="button"
                    onClick={() => setShowNewPassword(!showNewPassword)}
                    className='absolute inset-y-0 right-4 flex items-center'
                    aria-label={showNewPassword ? 'Hide new password' : 'Show new password'}
                  >
                    {showNewPassword ? <AiOutlineEyeInvisible size={24} /> : <AiOutlineEye size={24} />}
                  </button>
                </div>
              </div>
              <div className='flex flex-col mt-4 relative'>
                <label className='text-lg font-medium'>Confirm New Password</label>
                <div className='relative'>
                  <input
                    className='w-full border-2 border-gray-100 rounded-xl p-4 mt-1 bg-transparent pr-12'
                    placeholder="Confirm your new password"
                    type={showConfirmNewPassword ? 'text' : 'password'}
                    value={confirmNewPassword}
                    onChange={(e) => setConfirmNewPassword(e.target.value)}
                  />
                  <button
                    type="button"
                    onClick={() => setShowConfirmNewPassword(!showConfirmNewPassword)}
                    className='absolute inset-y-0 right-4 flex items-center'
                    aria-label={showConfirmNewPassword ? 'Hide confirm new password' : 'Show confirm new password'}
                  >
                    {showConfirmNewPassword ? <AiOutlineEyeInvisible size={24} /> : <AiOutlineEye size={24} />}
                  </button>
                </div>
              </div>
            </>
          )}

          {error && <p style={{ color: 'red' }}>{error}</p>}

          <div className='mt-8 flex flex-col gap-y-4'>
            <button
              type="submit"
              className='active:scale-[.98] active:duration-75 transition-all hover:scale-[1.01] ease-in-out transform py-4 bg-violet-500 rounded-xl text-white font-bold text-lg'
            >
              {isForgotPassword ? (otpSent ? 'Reset Password' : 'Send OTP') : 'Sign in'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Form;

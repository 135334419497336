import React, { useEffect ,useState} from 'react';
import { IoPerson, IoPeople, IoStorefront, IoCart, IoCash } from 'react-icons/io5';
import { useLocation, useParams } from 'react-router-dom';
function DashboardStatsGrid({ userData }) {
    const [walletAmount, setWalletAmount] = useState(0);
    const { id } = useParams();
    useEffect(()=>{
        FetchMoney()
    },[])
    const FetchMoney = async () => {
        try {
          const response = await fetch(`https://backend.crumbell.in/api/v1/user/${localStorage.getItem("UserId")}/active-money`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': "Bearer " + localStorage.getItem("token"),
            },
          });
          const data = await response.json();
          console.log('databkbmvbmmbvb',data)
          setWalletAmount(data?.activeMoney);
    
        } catch (error) {
          console.error('Error FetchMoney ', error);
        }
      };
    return (
        <div className='flex gap-4 w-full'>
            {console.log('Data:', userData)}

            <BoxWrapper>
                <div className='rounded-full h-12 w-12 flex items-center justify-center bg-sky-500'>
                    <IoPerson className='text-2xl text-white' />
                </div>
                <div className='pl-4'>
                    <span className='text-sm text-gray-500 font-light'>Managers</span>
                    <div className='flex items-center'>
                        <strong className='text-xl text-gray-700 font-semibold'>{userData?.managerCount}</strong>
                        <span className='text-sm text-green-500 pl-2'>+{userData?.newManagerCount}</span>
                    </div>
                </div>
            </BoxWrapper>
            <BoxWrapper>
                <div className='rounded-full h-12 w-12 flex items-center justify-center bg-red-500'>
                    <IoPeople className='text-2xl text-white' />
                </div>
                <div className='pl-4'>
                    <span className='text-sm text-gray-500 font-light'>Sales Executives</span>
                    <div className='flex items-center'>
                        <strong className='text-xl text-gray-700 font-semibold'>{userData?.salesExecutiveCount}</strong>
                        <span className='text-sm text-green-500 pl-2'>+{userData?.newSalesExecutiveCount}</span>
                    </div>
                </div>
            </BoxWrapper>
            <BoxWrapper>
                <div className='rounded-full h-12 w-12 flex items-center justify-center bg-yellow-500'>
                    <IoStorefront className='text-2xl text-white' />
                </div>
                <div className='pl-4'>
                    <span className='text-sm text-gray-500 font-light'>Shops</span>
                    <div className='flex items-center'>
                        <strong className='text-xl text-gray-700 font-semibold'>{userData?.shopCounts?.totalShopCount}</strong>
                        <span className='text-sm text-green-500 pl-2'>+{userData?.shopCounts?.newShopCount}</span>
                    </div>
                </div>
            </BoxWrapper>
            <BoxWrapper>
                <div className='rounded-full h-12 w-12 flex items-center justify-center bg-green-500'>
                    <IoCart className='text-2xl text-white' />
                </div>
                <div className='pl-4'>
                    <span className='text-sm text-gray-500 font-light'>Orders</span>
                    <div className='flex items-center'>
                        <strong className='text-xl text-gray-700 font-semibold'>{userData?.orderCount}</strong>
                        <span className='text-sm text-green-500 pl-2'>+{userData?.newOrderCount}</span>
                    </div>
                </div>
            </BoxWrapper>
            {/* New Section for Amount */}
            <BoxWrapper>
                <div className='rounded-full h-12 w-12 flex items-center justify-center bg-purple-500'>
                    <IoCash className='text-2xl text-white' />
                </div>
                <div className='pl-4'>
                    <span className='text-sm text-gray-500 font-light'>Wallet</span>
                    <div className='flex items-center'>
                        <strong className='text-xl text-gray-700 font-semibold'>{walletAmount}₹</strong>
                        {/* <span className='text-sm text-green-500 pl-2'>+${userData?.newAmount}</span> */}
                    </div>
                </div>
            </BoxWrapper>
        </div>
    );
}

function BoxWrapper({ children }) {
    return (
        <div className='bg-white rounded-sm p-4 flex-1 border border-gray-200 flex items-center'>
            {children}
        </div>
    );
}

export default DashboardStatsGrid;

import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import Loader from './shared/Loader';
import Toast from './shared/Toast';

const OrderTracking = () => {
  const navigate = useNavigate();
  const { orderId } = useParams();
  const location = useLocation();
  // const { order } = location.state || {};  // Retrieve passed order data
  const [loading, setLoading] = useState(false)
  const [toast, setToast] = useState({ message: '', visible: false });
  const [order, setOrder] = useState()
  console.log('Order Data:', order);

  const handlePress = (id) => {
    const url = `https://invoice.crumbell.in?id=${id}`; // Replace with your invoice URL
    window?.open(url, '_blank');
  };
  useEffect(()=>{
    fetchOrder()
  },[])
  const fetchOrder = async () => {
    try {
      setLoading(true);
      const response = await fetch(`https://backend.crumbell.in/api/v1/order/${orderId}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem("token")}`
        },
      });
      const data = await response.json();
      console.log('Fetched Orders:111111', data);
      setOrder(data)
    } catch (error) {
      console.error('Error fetching orders:', error);
    } finally {
      setLoading(false);
    }
  };

  const showToast = (message) => {
    setToast({ message, visible: true });
    setTimeout(() => setToast({ message: '', visible: false }), 2000); // Auto-hide after 2 seconds
  };
  const orderStatusChange = async (status) => {
    setLoading(true);
    try {
      const response = await fetch(`https://backend.crumbell.in/api/v1/order/${orderId}/${status}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem("token")}`
        },
      });
      const Response = await response.json();
      console.log('Response:', Response);
      showToast(Response.message); // Show success message after status change
      fetchOrder()
    } catch (error) {
      console.error('Error updating order status:', error);
      showToast('An error occurred while updating the order status.'); // Show error message
    } finally {
      setLoading(false);
    }
  };
  

  return (
    <div className="p-8 bg-white rounded-lg shadow-lg">
      {loading && <Loader />}
      {toast.message && <Toast message={toast.message} onClose={() => setToast({ ...toast, visible: false })} />}
      <h1 className="text-2xl font-bold mb-6 text-black">Order Tracking for Order ID: {orderId}</h1>

      {/* Order Tracker */}
      <div className="flex items-center justify-between mb-8">
        <div className="relative w-full">
          <div className="absolute inset-0 flex items-center">
            <div className="w-full border-t-4 border-gray-300"></div>
          </div>
          <div className="relative flex justify-between">
            <div
              className={`w-8 h-8 rounded-full border-4 border-white shadow-lg text-white flex items-center justify-center font-bold ${order?.statusHistory?.some((item) => item.status === 'Confirmed') ? 'bg-green-500' : 'bg-gray-500'
                }`}
            >
              1
            </div>

            <div
              className={`w-8 h-8 rounded-full border-4 border-white shadow-lg text-white flex items-center justify-center font-bold ${order?.statusHistory?.some((item) => item.status === 'Delivered_To_Manager') ? 'bg-green-500' : 'bg-gray-500'
                }`}
            >
              2
            </div>
            <div
              className={`w-8 h-8 rounded-full border-4 border-white shadow-lg text-white flex items-center justify-center font-bold ${order?.statusHistory?.some((item) => item.status === 'Confirmed_By_Delivery_Person') ? 'bg-green-500' : 'bg-gray-500'
                }`}
            >
              3
            </div>
            <div
              className={`w-8 h-8 rounded-full border-4 border-white shadow-lg text-white flex items-center justify-center font-bold ${order?.statusHistory?.some((item) => item.status === 'Completed') ? 'bg-green-500' : 'bg-gray-500'
                }`}
            >
              4
            </div>
          </div>
        </div>
      </div>

      {/* Order Status Labels */}
      <div className="flex justify-between mb-8">
        <span className={`${order?.statusHistory?.some((item) => item.status === 'Confirmed') ? 'text-green-500' : 'text-black'
          }`}>Order Placed</span>
        <span className={`${order?.statusHistory?.some((item) => item.status === 'Delivered_To_Manager') ? 'text-green-500' : 'text-black'
          }`}>Transit to Manager</span>
        <span className={`${order?.statusHistory?.some((item) => item.status === 'Confirmed_By_Delivery_Person') ? 'text-green-500' : 'text-black'
          }`}>Transit to Shop</span>
        <span className={`${order?.statusHistory?.some((item) => item.status === 'Completed') ? 'text-green-500' : 'text-black'
          }`}>Delivered</span>
      </div>

      {/* Action Buttons */}
      {
        (order?.orderStatus === 'Pending' || order?.orderStatus === 'Confirmed') &&

        <div className="grid grid-cols-2 gap-4">
          <div className="col-span-2 flex justify-center">
            <button
              onClick={() => {orderStatusChange(order?.orderStatus === 'Pending' ? 'confirm' : 'dispatch')}}
              className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded w-48"
            >
              {order?.orderStatus === 'Pending' ? 'Confirm Order' : 'Dispatch Order'}
            </button>
          </div>
        </div>
      }
      {
        (order?.orderStatus === 'Completed') &&

        <div className="grid grid-cols-2 gap-4">
          <div className="col-span-2 flex justify-center">
            <button
              onClick={() => {
                handlePress(orderId)
                // Your click handler logic here
              }}
              className="bg-[#EA4335] hover:bg-[#E57373] text-white font-bold py-2 px-4 rounded w-48"
            >
              Download Invoice
            </button>
          </div>
        </div>

      }

      {/* Display Order Details */}
      <div className="mt-6">
        {order && (
          <div>
            <h2 className="text-xl font-semibold mb-4 text-black">Order Details</h2>
            <p className='mt-0.5 text-black'><strong>Order Status:</strong> {order.orderStatus.replace(/_/g, '-')}</p>
            <p className='mt-0.5 text-black'><strong>Total Amount:</strong> {order.totalAmount} ₹</p>
            <p className='mt-0.5 text-black'><strong>Payment Status:</strong> {order.paymentStatus}</p>
            <p className='mt-0.5 text-black'><strong>Delivery Person Name:</strong> {order.deliveryPerson.name}</p>
            <p className='mt-0.5 text-black'><strong>Delivery Location:</strong> {order?.deliveryAddress?.addressLine1}, {order?.deliveryAddress?.addressLine2}, {order?.deliveryAddress?.city}, {order?.deliveryAddress?.state}, {order?.deliveryAddress?.country}</p>

            <h2 className="text-xl font-semibold mb-4 mt-4 text-black">Consumer Details</h2>
            <p className='mt-0.5 text-black'><strong>Item Name:</strong> {order.items[0].inventory.item.name}</p>
            <p className='mt-0.5 text-black'><strong>Shop Name:</strong> {order.shop.name}</p>
            <p className='mt-0.5 text-black'><strong>Shop Owner Name:</strong> {order.shop.owner}</p>

            {/* Add more order details as needed */}
          </div>
        )}
      </div>
    </div>
  );
};

export default OrderTracking;

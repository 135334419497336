import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom'; // Removed unused Link import
import Loader from './shared/Loader';
import { ThemeContext } from './ThemeContext';

const Order = ({ searchQuery }) => { // Destructure searchQuery
  const [sortedOrders, setSortedOrders] = useState([]);
  const [sortBy, setSortBy] = useState('default');
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalOrders, setTotalOrders] = useState(0);
  const { mode } = useContext(ThemeContext);
  const navigate = useNavigate();

  const fetchOrders = async (page = 1) => {
    try {
      setLoading(true);
      const response = await fetch(`https://backend.crumbell.in/api/v1/orders?page=${page}&sortBy=createdAt:desc`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem("token")}`
        },
      });
      const data = await response.json();
      console.log('Fetched Orders:', data);
      setSortedOrders(data.orders);
      setCurrentPage(Number(data.currentPage));
      setTotalPages(data.totalPages);
      setTotalOrders(data.totalOrders); // Adjust based on your API response structure
    } catch (error) {
      console.error('Error fetching orders:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  let filteredManagers = sortedOrders;

  if (searchQuery.length > 2) {
    filteredManagers = sortedOrders.filter(order =>
      order.items[0]?.inventory?.item?.name?.toLowerCase().includes(searchQuery.toLowerCase())
    );
  } else {
    filteredManagers = sortedOrders;
  }

  const handleSort = (option) => {
    let sortedList;
    switch (option) {
      case 'recentlyAdded':
        sortedList = [...sortedOrders].sort((a, b) => new Date(b.orderDate) - new Date(a.orderDate));
        break;
      case 'oldestAdded':
        sortedList = [...sortedOrders].sort((a, b) => new Date(a.orderDate) - new Date(b.orderDate));
        break;
      case 'orderName':
        sortedList = [...sortedOrders].sort((a, b) => a.shop.name.localeCompare(b.shop.name));
        break;
      default:
        sortedList = sortedOrders;
    }
    setSortedOrders(sortedList);
    setSortBy(option);
    setDropdownOpen(false); // Close the dropdown after selecting an option
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      fetchOrders(newPage);
    }
  };

  return (
    <div className="p-4 bg-white rounded-lg shadow-lg">
      {loading && <Loader />}
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-bold mb-4 text-black">Order List</h1>
        <div className="relative inline-block text-left">
          <button
            className="inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none"
            id="options-menu"
            aria-haspopup="true"
            aria-expanded={dropdownOpen ? 'true' : 'false'}
            onClick={toggleDropdown}
          >
            Sort By
            <svg
              className={`-mr-1 ml-2 h-5 w-5 ${dropdownOpen ? 'transform rotate-180' : ''}`}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M5.293 9.293a1 1 0 011.414 0L10 12.586l3.293-3.293a1 1 0 011.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
          {dropdownOpen && (
            <div
              className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="options-menu"
            >
              <div className="py-1" role="none">
                <button
                  className={`block px-4 py-2 text-sm ${sortBy === 'recentlyAdded' ? 'bg-gray-200' : 'text-gray-700'} hover:bg-gray-100 w-full text-left`}
                  role="menuitem"
                  onClick={() => handleSort('recentlyAdded')}
                >
                  Recently Added
                </button>
                <button
                  className={`block px-4 py-2 text-sm ${sortBy === 'oldestAdded' ? 'bg-gray-200' : 'text-gray-700'} hover:bg-gray-100 w-full text-left`}
                  role="menuitem"
                  onClick={() => handleSort('oldestAdded')}
                >
                  Oldest Added
                </button>
                <button
                  className={`block px-4 py-2 text-sm ${sortBy === 'orderName' ? 'bg-gray-200' : 'text-gray-700'} hover:bg-gray-100 w-full text-left`}
                  role="menuitem"
                  onClick={() => handleSort('orderName')}
                >
                  Order Name
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      <table className="min-w-full border border-gray-300 mt-4">
        <thead className="bg-gray-50">
          <tr>
            <th className="text-black border border-gray-300 px-2 py-2">Order ID</th>
            <th className="text-black border border-gray-300 px-2 py-2">Item Name</th>
            <th className="text-black border border-gray-300 px-2 py-2">Quantity</th>
            <th className="text-black border border-gray-300 px-2 py-2">Date Added</th>
            <th className="text-black border border-gray-300 px-2 py-2">Cost</th>
            <th className="text-black border border-gray-300 px-2 py-2">Shop Name</th>
            <th className="text-black border border-gray-300 px-2 py-2">Order Status</th>
          </tr>
        </thead>
        <tbody className="bg-white">
          {filteredManagers.map((order) => (
            <tr key={order._id}>
              <td className="text-black border border-gray-300 px-4 py-2">{order._id}</td>
              <td
                className={`text-black border border-gray-300 px-4 py-2 cursor-pointer ${mode === 'Dark' ? 'text-white hover:text-blue-300' : 'text-black hover:text-blue-700'} transition duration-300`}
                onClick={() => {
                  navigate(`/order-tracking/${order._id}`, { state: { order } });
                }}
              >
                {order.items[0].inventory.item.name}
              </td>
              <td className="text-black border border-gray-300 px-4 py-2">{order.items[0].quantity}</td>
              <td className="text-black border border-gray-300 px-4 py-2">
                {new Date(order.orderDate).toLocaleDateString('en-GB')}
              </td>
              <td className="text-black border border-gray-300 px-4 py-2">{order.totalAmount}</td>
              <td className="text-black border border-gray-300 px-4 py-2">{order.shop.name}</td>
              <td className="text-black border border-gray-300 px-4 py-2">{order.orderStatus.replace(/_/g, ' ')}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="flex justify-between items-center mt-4">
        <button
          className="bg-gray-300 text-black py-2 px-4 rounded disabled:opacity-50"
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <span className="text-black">
          Page {currentPage} of {totalPages == 0?1:totalPages}
        </span>
        <button
          className="bg-gray-300 text-black py-2 px-4 rounded disabled:opacity-50"
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Order;
